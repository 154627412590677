import React from 'react'
import PropTypes from 'prop-types'

const LoadingProgram = ({ loading }) => {
  if (!loading) {
    return null
  }

  return (
    <div className='ph-item'>
      <div className='ph-col-12'>
        <div className='ph-row'>
          <div className='ph-col-12 big' />
        </div>
        <div className='ph-picture' />
        <div className='ph-row'>
          <div className='ph-col-12 big' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-4 big' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-12' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-12' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-12' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-12' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-12' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-8' />
        </div>
        <div className='ph-row'>
          <div className='ph-col-4 big' />
        </div>
        <div className='ph-picture' />
        <div className='ph-row'>
          <div className='ph-col-4 big' />
        </div>
        <div className='ph-picture' />
        <div className='ph-picture' />
        <div className='ph-picture' />
      </div>
    </div>
  )
}

LoadingProgram.propTypes = {
  loading: PropTypes.bool.isRequired
}

export default LoadingProgram
